import React, { useEffect, useRef, useState } from 'react'
import Mapp from '../UI/Mapp'
import { RouteContext } from '../config/RouteContext'
import { ReactComponent as LoadingSVG } from '../assets/loader.svg'
import { ReactComponent as GuidosLogo } from '../assets/Logo_Guidos.svg'
import { mapWidgetElement } from '../utils/responsive'
import { ElevationProfile } from '../Profiler/ElevationProfile'
import { useAtom } from 'jotai'

import '../Style/Card.module.css'
import { computeGeoDataArray, resetArrays } from '../utils/calculations'

import i18n from 'i18next'
import '../translation/i18n'
import { Api } from '../../api/Api'
import { apiResultAtom } from '../../state/state'
import { createPortal } from 'react-dom'

let languageFromTag = false

const Container = () => {
	const elevationRef = useRef()
	const setRoutes = (routes) => {
		setGeoData(computeGeoDataArray(routes))
	}
	const [geodata, setGeoData] = useState({})
	const [isLoading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [currentMarker, setCurrentMarker] = useState([])
	const [currentPoi, setCurrentPoi] = useState('')
	const [language, setLanguage] = useState('fr')

	const [primaryColor, setPrimaryColor] = useState('#00AB84')
	const [secondaryColor, setSecondaryColor] = useState('#051B45')
	const [backgroundColor, setBackgroundColor] = useState('#051B45')
	const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF')
	const [buttonBackgroundColor, setButtonBackgroundColor] = useState('#051C44')
	const [elevationProfileColor, setElevationProfileColor] = useState('#00AB84')
	const [dotMapColor, setDotMapColor] = useState('#13b792')

	const [lineMapSize, setLineMapSize] = useState(6)
	const [radiusButton, setRadiusButton] = useState(true)

	const [displayGpxButton, setDisplayGpxButton] = useState(true)
	const [displayLanguageButton, setDisplayLanguageButton] = useState(true)
	const [displayInfoButton, setDisplayInfoButton] = useState(true)

	const [displayFullscreenButton, setDisplayFullscreenButton] = useState(false)
	const [displayGeoLocationButton, setDisplayGeoLocationButton] = useState(false)
	const [display3DRotateButton, setDisplay3DRotateButton] = useState(false)
	const [swisstopo, setSwissTopo] = useState(false)

	// api result
	const [apiResult, setApiResult] = useAtom(apiResultAtom)

	useEffect(() => {
		setLoading(true)
		resetArrays()
		i18n.changeLanguage(language)
		const tag = mapWidgetElement()
		const queryParams = new URLSearchParams(window.location.search)

		if (tag !== null && tag.getAttribute('primaryColor') !== undefined && tag.getAttribute('primaryColor') !== null) {
			setPrimaryColor(tag.getAttribute('primaryColor'))
		}

		if (
			tag !== null &&
			tag.getAttribute('secondaryColor') !== undefined &&
			tag.getAttribute('secondaryColor') !== null
		) {
			setSecondaryColor(tag.getAttribute('secondaryColor'))
		}

		if (
			tag !== null &&
			tag.getAttribute('backgroundColor') !== undefined &&
			tag.getAttribute('backgroundColor') !== null
		) {
			setBackgroundColor(tag.getAttribute('backgroundColor'))
		}

		if (
			tag !== null &&
			tag.getAttribute('buttonTextColor') !== undefined &&
			tag.getAttribute('buttonTextColor') !== null
		) {
			setButtonTextColor(tag.getAttribute('buttonTextColor'))
		}

		if (
			tag !== null &&
			tag.getAttribute('buttonBackgroundColor') !== undefined &&
			tag.getAttribute('buttonBackgroundColor') !== null
		) {
			setButtonBackgroundColor(tag.getAttribute('buttonBackgroundColor'))
		}

		if (
			tag !== null &&
			tag.getAttribute('elevationProfileColor') !== undefined &&
			tag.getAttribute('elevationProfileColor') !== null
		) {
			setElevationProfileColor(tag.getAttribute('elevationProfileColor'))
		}

		if (tag !== null && tag.getAttribute('dotMapColor') !== undefined && tag.getAttribute('dotMapColor') !== null) {
			setDotMapColor(tag.getAttribute('dotMapColor'))
		}

		if (tag !== null && tag.getAttribute('radiusButton') !== undefined && tag.getAttribute('radiusButton') !== null) {
			setRadiusButton(tag.getAttribute('radiusButton') === 'true' ? true : false)
		}

		if (tag !== null && tag.getAttribute('lineMapSize') !== undefined && tag.getAttribute('lineMapSize') !== null) {
			setLineMapSize(parseInt(tag.getAttribute('lineMapSize')))
		}

		if (
			tag !== null &&
			tag.getAttribute('displayGpxButton') !== undefined &&
			tag.getAttribute('displayGpxButton') !== null
		) {
			setDisplayGpxButton(tag.getAttribute('displayGpxButton') === 'true' ? true : false)
		}

		if (
			tag !== null &&
			tag.getAttribute('displayLanguageButton') !== undefined &&
			tag.getAttribute('displayLanguageButton') !== null
		) {
			setDisplayLanguageButton(tag.getAttribute('displayLanguageButton') === 'true' ? true : false)
		}

		if (
			tag !== null &&
			tag.getAttribute('displayInfoButton') !== undefined &&
			tag.getAttribute('displayInfoButton') !== null
		) {
			setDisplayInfoButton(tag.getAttribute('displayInfoButton') === 'true' ? true : false)
		}

		if (
			tag !== null &&
			tag.getAttribute('displayFullscreenButton') !== undefined &&
			tag.getAttribute('displayFullscreenButton') !== null
		) {
			setDisplayFullscreenButton(tag.getAttribute('displayFullscreenButton') === 'true' ? true : false)
		}

		if (
			tag !== null &&
			tag.getAttribute('displayGeoLocationButton') !== undefined &&
			tag.getAttribute('displayGeoLocationButton') !== null
		) {
			setDisplayGeoLocationButton(tag.getAttribute('displayGeoLocationButton') === 'true' ? true : false)
		}

		if (
			tag !== null &&
			tag.getAttribute('display3DRotateButton') !== undefined &&
			tag.getAttribute('display3DRotateButton') !== null
		) {
			setDisplay3DRotateButton(tag.getAttribute('display3DRotateButton') === 'true' ? true : false)
		}

		if (tag !== null && tag.getAttribute('swisstopo') !== undefined && tag.getAttribute('swisstopo') !== null) {
			setSwissTopo(tag.getAttribute('swisstopo') === 'true' ? true : false)
		}

		if (tag === null || (tag.getAttribute('fullscreen') !== undefined && tag.getAttribute('fullscreen') !== null)) {
			document.body.style.position = 'fixed'
		}

		if (
			tag !== null &&
			tag.getAttribute('language') !== undefined &&
			tag.getAttribute('language') !== null &&
			languageFromTag === false
		) {
			setLanguage(tag.getAttribute('language'))
			languageFromTag = true
		} else if (queryParams.get('language') !== null) {
			setLanguage(queryParams.get('language').toString())
			languageFromTag = true
		}

		getAPI()
	}, [language])

	const getAPI = () => {
		resetArrays()
		setError(false)
		setLoading(true)

		const queryParams = new URLSearchParams(window.location.search)
		const tag = mapWidgetElement()

		const url = tag.getAttribute('route')
			? tag.getAttribute('route')
			: `https://apivs.guidos.fun/entities/${queryParams.get('route')}`

		const options = {
			language: language,
			key: tag.getAttribute('key') || undefined,
		}

		new Api()
			.getTrail(url, options)
			.then((res) => {
				setApiResult(res)
				setRoutes(res)
				setLoading(false)
			})
			.catch((err) => {
				console.error(err)
				setError(true)
				setLoading(false)
			})
	}

	// reload API if attribute `route` changes
	useEffect(() => {
		const callback = (mutations, observer) => {
			if (mutations.some((mutation) => mutation.attributeName === 'route')) {
				getAPI()
			}
		}

		const targetNode = document.getElementById('guidosHighlight')
		const config = { attributes: true, childList: false, subtree: false }
		const observer = new MutationObserver(callback)
		observer.observe(targetNode, config)
	}, [])

	if (error) {
		return (
			<div
				style={{
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					alignContent: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
					background: 'lightgrey',
				}}
			>
				une erreur s'est produite
			</div>
		)
	}

	if (isLoading) {
		return (
			<div
				style={{
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					alignContent: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
				}}
			>
				<LoadingSVG width={100} fill={primaryColor} style={{ color: primaryColor }} />
			</div>
		)
	}

	return (
		<RouteContext.Provider
			value={{
				geodata,
				currentMarker,
				setCurrentMarker,
				currentPoi,
				setCurrentPoi,
				language,
				setLanguage,
				primaryColor,
				secondaryColor,
				backgroundColor,
				buttonTextColor,
				buttonBackgroundColor,
				elevationProfileColor,
				dotMapColor,
				radiusButton,
				lineMapSize,
				displayGpxButton,
				displayLanguageButton,
				displayInfoButton,
				displayFullscreenButton,
				displayGeoLocationButton,
				display3DRotateButton,
				swisstopo,
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
				<div style={{ flex: '1', position: 'relative', zIndex:10 }}>
					<Mapp elevationRef={elevationRef} />
				</div>
				<ElevationProfile ref={elevationRef} />
			</div>
		</RouteContext.Provider>
	)
}

export default Container
