
import GraphicChart from './GraphicChart'
import styles from '../Style/Elevation.module.css'
import { isMobileScreen, isSmallHeight, mapWidgetElement } from '../utils/responsive';
import { forwardRef, useEffect, useImperativeHandle, useState, useContext } from 'react';
import {setTooltipUpdateFunction} from '../utils/markerCoordinates';
import { RouteContext } from '../config/RouteContext';
import _ from "lodash";
import { refreshProfile as refreshProfileState } from '../../state/state';
import { useAtom } from 'jotai';

export const ElevationProfile = forwardRef((props, ref) => {

    const { backgroundColor } = useContext(RouteContext);

    const [chartIndex, setChartIndex] = useState(-1);

    const [chartLastIndex, setChartLastIndex] = useState(-1);
    const [refresh, setRefresh] = useState(false);
    const [refreshProfile] = useAtom(refreshProfileState)

    const updateChartIndex = (newValue) => {

        setChartIndex(newValue);
        setChartLastIndex(-1);
    }

    useEffect(() => {
        setTooltipUpdateFunction(updateChartIndex);
    })



    useImperativeHandle(ref, () => ({
        updateChartIndex(newIndex) {
            setChartIndex(newIndex);
        },
        updateChartLastIndex() {
            setChartLastIndex(-1);
            setChartIndex(-1);
        },
    }))



    const updateChartIndexFromTooltip = (newIndex) => {
        // setChartIndex(newIndex);
    }

    const updateChartLastIndexFromTooltip = (newIndex) => {
        // setChartLastIndex(newIndex);
    }

    const height = isSmallHeight() || isMobileScreen() ? 168 : 246;
    const width = mapWidgetElement().clientWidth

    // resize the Profile when window is resized
    window.addEventListener('resize', _.debounce(() => {
        setRefresh(!refresh)
    }, 1000))

    useEffect(() => {
        setRefresh(!refresh)
    }, [refreshProfile])
    
    return (

        <div className={`${styles['elevation']} prevent-select`} style={{zIndex:20, width: width, height: height, background: backgroundColor }}>
        <GraphicChart 
                    chartLastIndex={chartLastIndex} 
                    setChartLastIndex={updateChartLastIndexFromTooltip} 
                    chartIndex={chartIndex} 
                    setChartIndex={updateChartIndexFromTooltip} />
            </div>

    )
});
