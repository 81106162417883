import React, { useState, useContext, useRef, useEffect, Suspense, useCallback } from 'react';
import { MAPBOX_KEY } from '../config/config'
import ReactMapGL, { Marker, Popup, Source, Layer, FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import PointOfInterest from '../Modals/Poi'
import { API_URL } from '../config/config'
import RightButtons from './RightButtons'
import LeftButtons from './LeftButtons'
import { RouteContext } from '../config/RouteContext'
import { ReactComponent as StartIcon } from '../assets/start_icon.svg'
import { ReactComponent as EndIcon } from '../assets/end_icon.svg';
import { isMobileScreen, isSmallHeight, mapWidgetElement } from '../utils/responsive';

import mapboxgl from 'mapbox-gl';
import { getMapBounds, isPointOnLine } from '../utils/calculations';
import { CurrentMarkerIcon } from './CurrentMarkerIcon';
import { updateMarkerFromTooltip } from '../utils/markerCoordinates';
import { useAtom } from 'jotai';
import { apiResultAtom } from '../../state/state';
import { poiCategoryName, poiIconUrl } from '../utils/poiHelper';
import _ from "lodash";
import { changeMapboxUrlLogo } from '../utils/mapboxLogoUrl';
import { swisstopoLayer } from '../utils/swissTopoLayer';

const responsiveZoom = () => {

    const websiteWidth = mapWidgetElement().clientWidth;

    if (websiteWidth < 700) {
        return 10.3;
    }

    if (websiteWidth < 400) {
        return 10.2;
    }
    if (websiteWidth < 500) {
        return 10.4
    }
    if (websiteWidth < 600) {
        return 10.6
    }
    if (websiteWidth < 800) {
        return 10.8
    }
    if (websiteWidth < 1000) {
        return 11
    }
    return 11.5;
}



try {
    function workerCros(url) {
        const iss = "importScripts('" + url + "');";
        return URL.createObjectURL(new Blob([iss]));
    }

    const workerUrl = workerCros(new URL("https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl-csp-worker.js", window.location).href);
    if (workerUrl !== null) {
        mapboxgl.workerUrl = workerUrl;
    }
} catch (error) {
    console.log(error);
}

const Mapp = (props) => {

    const mapContainer = useRef();
    const [scrollZoomEnabled, setScrollZoomEnabled] = useState(false);

    const { currentPoi, setCurrentPoi, language, secondaryColor, lineMapSize, swisstopo } = useContext(RouteContext)

    const [apiResult] = useAtom(apiResultAtom)
    const currentMarkerRef = useRef();

    const [viewport, setViewport] = useState({
        latitude: apiResult.base.latitude,
        longitude: apiResult.base.longitude,
        zoom: responsiveZoom(),
        interactive: false,
        pitch: 0,
    });

    const [currentPlace, setCurrentPlace] = useState(null)
    const mapStyle = swisstopo
        ? 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'
        : 'mapbox://styles/tokiwi/ckrc2rl1l0g3q18qi4wvom6ai'

	const [mapS, setMapS] = useState(mapStyle)

    const [elevation, setElevation] = useState(0);
    const [mobileModalOpen, setMobileModalOpen] = useState(false);
    const [isButtonActive, setButtonActive] = useState(false);

    const handleMarkerClick = id => {
        setCurrentPlace(id);
        setMobileModalOpen(true);
    }

    const handleRotateClick = (rotationDirection = "RIGHT") => {
        const bearing = rotationDirection === "RIGHT" ? viewport.bearing - 45 : viewport.bearing + 45;

        setViewport({
            ...viewport,
            bearing: bearing,
        })
    }

    const changeMapStyle = (buttonIndex) => {

        setButtonActive(false);
        if (buttonIndex === 0) {
            if (elevation === 1.5) {
                setMapS(mapS);
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: 0 });
                setElevation(0);
                setViewport({
                    ...viewport,
                    pitch: 0,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator()
                })
            }
            else {
                setMapS(mapS);
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
                setElevation(1.5);
                setViewport({
                    ...viewport,
                    pitch: 60,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator()
                })
            }

        }
        else if (buttonIndex === 1) {
            mapContainer.current.getMap(0).on('styledata', (props) => {
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: elevation });
            });
            if (mapS === 'mapbox://styles/tokiwi/ckrc2rl1l0g3q18qi4wvom6ai' || mapS === 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json') {
                setMapS('mapbox://styles/mapbox/satellite-streets-v11');
            }
            else {
                setMapS(swisstopo
                    ? 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'
                    : 'mapbox://styles/tokiwi/ckrc2rl1l0g3q18qi4wvom6ai')
            }
        }

    }

    useEffect(() => {
        //If the currentPoi context was changed, we set the currentPlace state to the name provided by the GraphicChart Component
        if (currentPoi.length > 0) {
            setCurrentPlace(currentPoi);
            setMobileModalOpen(true);
        }
        else {
            setCurrentPlace(null);
            setMobileModalOpen(false);
        }
    }, [currentPoi])// eslint-disable-line react-hooks/exhaustive-deps

    const ResponsivePoiPopups = (p) => {
        if (p.id === currentPlace) {
            if (!isMobileScreen()) {
                return (
                    <Popup latitude={p.base.latitude} longitude={p.base.longitude} closeButton={false} closeOnClick={true} anchor="top">
                        <PointOfInterest name={p.base.title[language] || p.base.title["fr"] || ""} subname={poiCategoryName(p.base.categories[0], language)} description={p.base.description[language] || p.base.description["fr"] || ""} image={p.base.image} icon={poiIconUrl(p.base.categories[0])}
                            close={() => {
                                setCurrentPlace(null);
                                setCurrentPoi("");
                            }} />
                    </Popup>
                )
            }
            return (

                <div style={{display:'flex', alignContent:'center', justifyContent: 'center'}}>
                    {mobileModalOpen && (
                        <div style={{ position: 'absolute', maxHeight: '90%', maxWidth:'100%', overflow: 'auto', top: 10, width: 310, zIndex: 930, display:'flex' }}>
                            <PointOfInterest name={p.base.title[language] || p.base.title["fr"] || ""} subname={poiCategoryName(p.base.categories[0], language)} description={p.base.description[language] || p.base.description["fr"] || ""} image={p.base.image} icon={poiIconUrl(p.base.categories[0])}
                                close={() => {
                                    setMobileModalOpen(false)
                                    setCurrentPlace(null);
                                    setCurrentPoi("");
                                }} />
                        </div>
                    )}
                </div>
            )
        }
        return null;
    }

    const mouseEvent = useRef(null)
    // debounce the update of the marker on chart
    // to avoid laggy behaviour when moving on the trail
    const updateMarkerOnChart = useCallback(_.debounce(() => {
        const linePoint = isPointOnLine(mouseEvent.current.lngLat);
        currentMarkerRef.current.updateMarker(linePoint.coordinates);
        updateMarkerFromTooltip(linePoint.index);
    }, 1), []);

    // adjust map, because the profile chart
    let height = isSmallHeight() || isMobileScreen() ? 168 : 246;
    const mapHeight = `calc(100% - ${height}px)`;
    
    return (
        <React.Fragment>

            <ReactMapGL
                ref={mapContainer}
                {...viewport} 
                width={"100%"} 
                mapboxApiAccessToken={MAPBOX_KEY}
                onViewportChange={nextViewport => {
                    if (!isButtonActive) {
                        setViewport({ ...nextViewport })
                    }
                }}
                onMouseMove={(event) => {
                    if (!event.hasOwnProperty("changedPointers")) {
                        updateMarkerOnChart();
                        mouseEvent.current = event;
                    }
                }}
                mapStyle={mapS}
                height="100%"
                scrollZoom={scrollZoomEnabled}
                onWheel={(event) => {
                    // see https://github.com/mapbox/mapbox-gl-js/issues/6884
                    // this is implemented natively on more recent version of mapbox-gl
                    if (event.srcEvent.ctrlKey) {
                        event.srcEvent.preventDefault();
                        if (!scrollZoomEnabled) setScrollZoomEnabled(true); // Enable scrollZoom if it's not enabled
                    } else {
                        if (scrollZoomEnabled) setScrollZoomEnabled(false); // Disable scrollZoom if it's enabled
                    }
                }}
                onLoad={() => {
                    changeMapboxUrlLogo();

                    const bounds = getMapBounds(apiResult.trail.geometry);
                    const { longitude, latitude, zoom } = new WebMercatorViewport(viewport)
                        .fitBounds(bounds, { padding: { top: 10, bottom: 20, left: 10, right: isMobileScreen() ? 100 : 10 } });

                    setViewport({
                        ...viewport,
                        latitude: latitude,
                        longitude: longitude,
                        zoom: zoom,
                        transitionDuration: 500,
                        transitionInterpolator: new FlyToInterpolator()
                    })
                }}
            >
                <Suspense fallback="Loading...">
                    <RightButtons mapStyle={changeMapStyle} currentMapStyle={mapS} currentElevation={elevation} setButtonActive={setButtonActive} handleRotateClick={handleRotateClick} />
                    <LeftButtons setButtonActive={setButtonActive} />
                </Suspense>

				{ (swisstopo && mapS !== 'mapbox://styles/mapbox/satellite-streets-v11' ) &&
					<Source id='wmts-test-layer' type='raster' tiles={['https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg']} tileSize={256}>
					    <Layer {...swisstopoLayer} />
					</Source>
				}

                <CurrentMarkerIcon ref={currentMarkerRef} />

                {/* STARTING POINT */}
                <Marker latitude={apiResult.trail.startLocation[1]} longitude={apiResult.trail.startLocation[0]} offsetLeft={-20} offsetTop={-10}>
                    <StartIcon style={{ width: "45px", maxWidth: "45px", height: 70, transform: "translate3d(0,-60%,0)" }} />
                </Marker>

                {/* ENDING POINT */}
                <Marker latitude={apiResult.trail.endLocation[1]} longitude={apiResult.trail.endLocation[0]} offsetLeft={-20} offsetTop={-10}>
                    <EndIcon style={{ width: "45px", maxWidth: "45px", height: 70, transform: "translate3d(-5px,-60%,0)" }} />
                </Marker>

                {
                    apiResult.trail.pois.map(p => (
                        <React.Fragment key={p.id}>
                            <Marker latitude={p.base.latitude} longitude={p.base.longitude} offsetLeft={-20} offsetTop={-10}>
                            <img src={poiIconUrl(p.base.categories[0])} alt='point-of-interest-marker' style={{ width: "45px", maxWidth: "45px", transform: "translate3d(0,-50%,0)", zIndex: -1, cursor: "pointer" }} onClick={() => handleMarkerClick(p.id)} />
                            </Marker>
                            {//Mapping through the points of interest, if any of them matches the name of current place
                                //we render the POI popup
                                ResponsivePoiPopups(p)
                            }
                        </React.Fragment>
                    ))
                }

                <Source id='routeSource' type='geojson' data={{ type: "LineString", coordinates: apiResult.trail.geometry}} />
                <Layer
                    id='routeLayer'
                    type='line'
                    source='routeSource'
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                        'line-sort-key': -9999,
                    }}
                    paint={{
                        'line-color': secondaryColor,
                        'line-width': lineMapSize,
                    }}
                />

                <Source id='mapbox-dem' type='raster-dem' url='mapbox://mapbox.mapbox-terrain-dem-v1' tileSize={512} maxzoom={16} />
                <Layer
                    id='sky'
                    type='sky'
                    paint={{
                        "sky-type": "atmosphere",
                        "sky-atmosphere-sun": [0.0, 90.0],
                        "sky-atmosphere-sun-intensity": 15,
                    }}
                />
            </ReactMapGL >
        </React.Fragment>
    )
};

export default Mapp;
