export const swisstopoLayer = {
    id: 'wmts-test-layer',
    type: 'raster',
    source: {
    type: 'raster',
    tiles: [
        'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
    ],
    tileSize: 256,
    attribution:
        'Map tiles by <a target="_top" rel="noopener" href="http://www.swisstopo.admin.ch">swisstopo</a>',
    },
    paint: {},
  };