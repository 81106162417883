import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext, memo } from 'react';
import { Marker } from 'react-map-gl';
import {setMapUpdateFunction} from '../utils/markerCoordinates';
import { RouteContext } from '../config/RouteContext'


const MapMarkerIcon = memo(function Foo(){
    const { dotMapColor } = useContext(RouteContext);

    return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g id="Ellipse_65" data-name="Ellipse 65" fill="current" stroke="#fff" strokeWidth="5">
            <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
            <circle cx="11.5" cy="11.5" r="9" fill={dotMapColor}/>
        </g>
    </svg>
})

export const CurrentMarkerIcon = forwardRef((props, ref) => {

    const [currentMarker, setCurrentMarker] = useState([]);

    const updateCurrentMarker = (newValue) => {
        if (currentMarker !== newValue) {
            setCurrentMarker(newValue);
        }
    }

    useEffect(() => {
        //The function from markerCoordinates.js is set here
        setMapUpdateFunction(updateCurrentMarker);
    }) 

    useImperativeHandle(ref, () => ({
        updateMarker(newMarker) {
            setCurrentMarker(newMarker);
        }
    }))

    return (
        currentMarker.length > 0 ? (
            <Marker latitude={currentMarker[1]} longitude={currentMarker[0]} offsetLeft={-20} offsetTop={-10}>
                <MapMarkerIcon />
            </Marker>
        ) : null

    )

})