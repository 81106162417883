import { Language } from "../../api/Models"
const Nature = require('../assets/poiType/5ed8ff783ecb2263427107.png');
const Restauration = require('../assets/poiType/5ed8ff78400a6456451325.png');
const Picknick = require('../assets/poiType/5ed8ff78412de565633562.png');
const Information = require('../assets/poiType/5ed8ff784363a797841843.png');
const Fontaine = require('../assets/poiType/5ed8ff784485a492861252.png');
const Borne = require('../assets/poiType/5ed8ff7845a4f344972287.png');
const Sport = require('../assets/poiType/5ed8ff7846bf8269242402.png');
const Hebergment = require('../assets/poiType/5ed8ff7847df3895023367.png');
const Telesiege = require('../assets/poiType/5ed8ff7842440210557548.png');
const Default = require('../assets/poiType/6018222578166413753111.png');
const Loisir = require('../assets/poiType/loisir.png')
const Culture = require('../assets/poiType/culture.png')
const Parking = require('../assets/poiType/6018222578166413753112.png')
const Vente = require('../assets/poiType/vente.png')
const Therme = require('../assets/poiType/therme.png')
const Passport = require('../assets/poiType/60183e7f3fff6730884106.png')
const Chrono = require('../assets/poiType/chrono.png')
const Ravitaillement = require('../assets/poiType/ravitaillement.png')
const Spectateur = require('../assets/poiType/spectateur.png')

export const poiIconUrl = (category: string) => {
    const poiCategory = poiCategoryMap[category as keyof typeof poiCategoryMap]
    if (!poiCategory) return Default

    const icon = poiCategory.icon
    if (!icon) return Default

    return icon
}

export const poiCategoryName = (category: string, language: Language) => {
    const icon = poiCategoryMap[category as keyof typeof poiCategoryMap]
    if (!icon) return ""

    const name = icon.name
    if (!name) return ""

    return name[language]
}

export const poiCategoryColor = (category: string) => {
    const icon = poiCategoryMap[category as keyof typeof poiCategoryMap]
    if (!icon) return "#212548"

    const color = icon.color
    if (!color) return "#212548"

    return color
}

const poiCategoryMap = {
    ch_1004: {
        name: {
            fr: 'Nature',
            de: 'Natur',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_0807: {
        name: {
            fr: 'Restaurants',
            de: 'Verpflegung',
        },
        icon: Restauration,
        color: "#F56E23"
    },
    ch_08: {
        name: {
            fr: 'Restauration',
            de: 'Verpflegung',
        },
        icon: Restauration,
        color: "#F56E23"
    },
    ch_0808: {
        name: {
            fr: 'Distillerie',
            de: 'Brennerei'
        },
        icon: Restauration,
        color: "#F56E23"
    },
    ch_081206: {
        name: {
            fr: 'Cave &amp; bar',
            de: 'Weinbar'
        },
        icon: Restauration,
        color: "#F56E23"
    },
    ch_0816: {
        name: {
            fr: 'Auberge de montagne',
            de: 'Alp-Sennerei'
        },
        icon: Restauration,
        color: "#F56E23"
    },
    ch_0827: {
        name: {
            fr: 'Ravitaillement',
            de: 'Versorgung',
        },
        icon: Restauration,
        color: "#C0972A"
    },
    ch_100111: {
        name: {
            fr: "Parc accrobranche",
            de: "Hochseilgarten"
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100122: {
        name: {
            fr: "Zoo",
            de: "Zoo und Tierpark"
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100124: {
        name: {
            fr: "Piste de luge d'été",
            de: "Sommerrodelbahn"
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100134: {
        name: {
            fr: "Parc de loisirs",
            de: "Freizeitpark"
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100135: {
        name: {
            fr: "Pique-nique",
            de: "Picknickplatz"
        },
        icon: Picknick,
        color: "#89c900"
    },
    ch_100204: {
        name: {
            fr: 'Musée',
            de: 'Museum',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100206: {
        name: {
            fr: 'Eglise, abbaye',
            de: 'Kirche',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100208: {
        name: {
            fr: 'Site historique ou patrimoine',
            de: 'Historische Stätte',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100210: {
        name: {
            fr: 'Centre culturel',
            de: 'Kulturzentrum',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100213: {
        name: {
            fr: 'Mine',
            de: 'Bergwerk',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100217: {
        name: {
            fr: 'Monastère',
            de: 'Kloster',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100219: {
        name: {
            fr: 'Chapelle',
            de: 'Kapelle',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100220: {
        name: {
            fr: 'Bibliothèque',
            de: 'Bibliothek',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100221: {
        name: {
            fr: 'Château',
            de: 'Schloss',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100224: {
        name: {
            fr: 'Forteresse',
            de: 'Festung',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100227: {
        name: {
            fr: 'Localité',
            de: 'Ortschaft',
        },
        icon: Culture,
        color: "#8da602"
    },
    ch_100314: {
        name: {
            fr: 'Curling',
            de: 'Curling',
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100332: {
        name: {
            fr: 'Golf',
            de: 'Golf',
        },
        icon: Loisir,
        color: "#a6027d"
    },
    ch_100402: {
        name: {
            fr: 'Sites naturels',
            de: 'Naturschutzgebiet',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_10040203: {
        name: {
            fr: 'Parc naturel régional (parc national)',
            de: 'Nationalpark',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_10040204: {
        name: {
            fr: 'Parc naturel régional',
            de: 'Naturpark',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100403: {
        name: {
            fr: 'Lac',
            de: 'See',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100404: {
        name: {
            fr: 'Parc',
            de: 'Park',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100406: {
        name: {
            fr: 'Col de montagne',
            de: 'Alpenpass',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100407: {
        name: {
            fr: 'Forêt',
            de: 'Wald',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100409: {
        name: {
            fr: 'Grotte',
            de: 'Höhle',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100413: {
        name: {
            fr: 'Gorges',
            de: 'Schlucht',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100426: {
        name: {
            fr: 'Glacier',
            de: 'Gletscher',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100427: {
        name: {
            fr: 'Pont',
            de: 'Brücke',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100428: {
        name: {
            fr: 'Source',
            de: 'Quelle',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100429: {
        name: {
            fr: 'Digue',
            de: 'Deich',
        },
        icon: Nature,
        color: "#30B2D1"
    },
    ch_100502: {
        name: {
            fr: 'Remontée mécanique',
            de: 'Bergstation',
        },
        icon: Telesiege,
        color: "#30B2D4"
    },
    ch_10052501: {
        name: {
            fr: 'Parking',
            de: 'Parkhaus',
        },
        icon: Parking,
        color: "#30B2D4"
    },
    ch_100609: {
        name: {
            fr: 'Fromagerie',
            de: 'Käserei',
        },
        icon: Vente,
        color: "#30B2D4"
    },
    ch_100610: {
        name: {
            fr: 'Vente directe',
            de: 'Direktvermarkter',
        },
        icon: Vente,
        color: "#30B2D5"
    },
    ch_100616: {
        name: {
            fr: 'Mode',
            de: 'Mode',
        },
        icon: Sport,
        color: "#E5478E"
    },
    ch_10070314: {
        name: {
            fr: 'Ecole professionnelle',
            de: 'Berufsschule',
        },
        icon: Information,
        color: "#DF1809"
    },
    ch_100803: {
        name: {
            fr: 'Therme',
            de: 'Thermalbad',
        },
        icon: Therme,
        color: "#30B2D5"
    },
    ch_100707: {
        name: {
            fr: 'Information',
            de: 'Information',
        },
        icon: Information,
        color: "#DF1809"
    },
    ch_100411: {

        name: {
            fr: 'Fontaine',
            de: 'Brunnen',
        },
        icon: Fontaine,
        color: "#126dd7"
    },
    ch_100528: {
        name: {
            fr: 'Borne de recharge',
            de: 'Ladestation',
        },
        icon: Borne,
        color: "#6a68f9"
    },
    ch_10052903: {
        name: {
            fr: 'Borne de recharge',
            de: 'Ladestation',
        },
        icon: Borne,
        color: "#6a68f9"
    },
    ch_100615: {
        name: {
            fr: 'Magasin de sport',
            de: 'Sportgeschäft',
        },
        icon: Sport,
        color: "#E5478E"
    },
    ch_07: {
        name: {
            fr: 'Hébergement',
            de: 'Unterkunft',
        },
        icon: Hebergment,
        color: "#02a66c"
    },

    ch_100503: {
        name: {
            fr: 'Télésiège',
            de: 'Sessellift',
        },
        icon: Telesiege,
        color: "#EEA62A"
    },
    pass: {
        name: {
            fr: 'Passeport valaisan',
            de: 'Passeport valaisan',
        },
        icon: Passport,
        color: "#30B2D5"
    },
    enigme: {
        name: {
            fr: 'Énigme by Escapelife',
            de: 'Énigme by Escapelife',
        },
        icon: Default,
        color: "#30B2D5"
    },
    ch_100113: {
        name: {
            fr: 'Chronométrage',
            de: 'Zeitmessung',
        },
        icon: Chrono,
        color: "#a6027d"
    },
    ch_0803: {
        name: {
            fr: 'Ravitaillement',
            de: 'Verpflegung',
        },
        icon: Ravitaillement,
        color: "#C0972A"
    },
    ch_100420: {
        name: {
            fr: 'Spectateurs',
            de: 'Zuschauer',
        },
        icon: Spectateur,
        color: "#C0972A"
    },
}
