import rightStyles from '../Style/RightButtons.module.css'
import { ReactComponent as GpxD } from '../assets/gpx-download.svg'
import { ReactComponent as Mobile } from '../assets/mobile.svg'
import { ReactComponent as Fullscreen } from '../assets/full-screen.svg'
import { ReactComponent as FullscreenExit } from '../assets/fullscreen-close.svg'
import { useContext, useState, useEffect, Suspense, useRef } from 'react'
import { RouteContext } from '../config/RouteContext'
import { downloadGPX } from '../utils/calculations';
import { API_URL } from '../config/config';
import { Close } from '@material-ui/icons'
import styled from '../Style/MoreInfo.module.css'
import { isMobileScreen, popupMobileDevice } from '../utils/responsive'
import Select from 'react-select'
import { ReactComponent as FranceFlag } from '../assets/france.svg';
import { ReactComponent as US } from '../assets/us.svg';
import { ReactComponent as Left } from '../assets/turn-left-black.svg';
import { ReactComponent as Right } from '../assets/turn-right-black.svg';
import { useTranslation } from "react-i18next";
import  { GeolocateControl, NavigationControl } from 'react-map-gl';
import { apiResultAtom } from '../../state/state';
import { useAtom } from 'jotai';
import screenfull from 'screenfull';
import { refreshProfile as refreshProfileState } from '../../state/state';
import { isIOS } from '../utils/plateform'

const options = [
    { value: 'en', label: <US width={22} height={18} style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', borderRadius: '3px' }} /> },
    { value: 'fr', label: <FranceFlag width={22} height={18} style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', borderRadius: '3px' }} /> },
]

const RightButtons = (props) => {
    const is3Denabled = props.currentElevation !== 0
    const [apiResult] = useAtom(apiResultAtom)

    const [popupOpen, setPopupOpen] = useState(false)
    const {
			language,
			setLanguage,
			routes,
			displayGpxButton,
			displayLanguageButton,
			displayFullscreenButton,
			displayGeoLocationButton,
			display3DRotateButton,
            radiusButton,
		} = useContext(RouteContext)

    const name = apiResult.base.title[language];
    const gpxUrl = apiResult.trail.gpxUrl;
    const [selectIndex, setSelectIndex] = useState(1);
    const { t } = useTranslation();

    const leftRadiusButton = radiusButton ? rightStyles["leftRadius"]  : rightStyles["no-radius"]
    const rightRadiusButton = radiusButton ? rightStyles["rightRadius"]  : rightStyles["no-radius"]
    const [refreshProfile, setRefreshProfile] = useAtom(refreshProfileState)
    const iosFullScreen = useRef(false)

    const classRadiusButton = radiusButton ? rightStyles["radius"]  : rightStyles["no-radius"]
    const classNavRadiusButton = radiusButton ? '' : rightStyles['nav-no-radius']

    useEffect(() => {
        if (language === 'en') {
            setSelectIndex(0);
        }
        else {
            setSelectIndex(1);
        }
    }, [language]);

    return (
        <Suspense fallback="Loading...">
            <div className={rightStyles["right"]}>

            {displayLanguageButton &&
                <Select
                    options={options}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    styles={{
                        dropdownIndicator: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                            width: 15,
                            height: 'auto',
                            color: "black",
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap',
                            paddingTop: 0
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            borderRadius: '10px',
                            padding: 0,
                            flexWrap: 'nowrap',
                            cursor: 'pointer',
                            backgroundColor:'transparent',

                        }),
                        menu: (provided) => ({
                            ...provided,
                            borderRadius:'10px',
                            overflow:"hidden",
                            border: "none",
                            boxShadow: 'none',
                            borderWidth: 0,
                            padding: 0,
                            display: 'flex',
                            flexWrap: 'nowrap',
                            width: '100%',
                        }),
                        container: (base) => ({
                            ...base,
                            borderRadius: '10px',
                            // width: '72px',
                            height: '42px',
                            padding: '3.6px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap !important',
                        }),
                        option: (base) => ({
                            ...base,
                            padding: 5,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent:'center',
                            flexWrap: 'nowrap !important',
                            width: '100%',
                            backgroundColor:'transparent',
                            cursor: 'pointer',
                            ':hover':{
                                backgroundColor: '#F2F2F2'
                            }
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: 3,
                            width: '100%',
                        }),
                        indicatorsContainer: (base) => ({
                            ...base,
                            flexWrap: 'nowrap',
                            height: 'auto'
                        }),
                        menuList: (base) => ({
                            ...base,
                            width: '100%',
                        })

                    }}
                    onMouseOver={() => props.setButtonActive(true)}
                    onMouseOut={() => props.setButtonActive(false)}
                    id="language"
                    isSearchable={false}
                    style={{ zIndex: 900 }}
                    value={options[selectIndex]}
                    className={`${rightStyles['language-selector']} ${classRadiusButton}`}
                    onChange={(event) => setLanguage(event.value)}
                />}
                <div style={{  height: !isMobileScreen() ? '90px' : 0, marginTop: 0, marginBottom: !isMobileScreen() ? 10 : 0, paddingRight: 0, marginRight: 0, borderRadius: 0 }} >
                    {!isMobileScreen() && <NavigationControl showCompass={false} className={`${classRadiusButton} ${classNavRadiusButton}`} style={{ position: "relative"}} />}
                </div>

     
                 <div style={{ display: "flex", width:'72px' }}>
                    {(display3DRotateButton && is3Denabled) &&
                        <button className={`${rightStyles['button-right-style-simple']} ${leftRadiusButton}`}  style={{ padding:'auto!important'}}  onClick={() => props.handleRotateClick("LEFT")}>
                            <Left style={{marginRight:'5px', marginLeft:'5px'}} />
                        </button>
                    }
                    <button 
                        onMouseOver={() => props.setButtonActive(true)} 
                        onMouseOut={() => props.setButtonActive(false)} 
                        className={`${rightStyles['button-right-style-simple']} ${is3Denabled ? `${rightStyles['active']}`: `${classRadiusButton}`}`}
                        onClick={() => props.mapStyle(0)}
                        style={{flex:'1'}}
                        >
                        {t('3dButton')}
                    </button>
                
                    {(display3DRotateButton && is3Denabled) &&
                        <button className={`${rightStyles['button-right-style-simple']} ${rightRadiusButton}`} style={{ width:'auto!important'}}  onClick={() => props.handleRotateClick("RIGHT")}>
                            <Right style={{marginRight:'5px', marginLeft:'5px'}} />
                        </button>
                    }
                </div>
                
      
                <button onMouseOver={() => props.setButtonActive(true)} onMouseOut={() => props.setButtonActive(false)} className={`${rightStyles['button-right-style']} ${classRadiusButton} ${props.currentMapStyle === 'mapbox://styles/mapbox/satellite-streets-v11' ? rightStyles['active'] : ''}`} onClick={() => props.mapStyle(1)} style={{fontSize:'12px'}}>{t('satteliteButton')}</button>
                {(!popupMobileDevice() && displayGpxButton) && (
                    <button className={`${rightStyles["button-right-style"]} ${classRadiusButton} ${rightStyles['w-svg']}`}
                        onClick={() => downloadGPX(name, `${gpxUrl}`)}>{<GpxD id="gpxButtonIcon" fill="#212548" style={{ marginRight: 2 }} />} {t('gpxRightButton')}</button>
                )}
                {false && !popupMobileDevice() && (
                    <div>

                        <button className={`${rightStyles["button-right-style"]} ${classRadiusButton} ${rightStyles['w-svg']}`}
                            onClick={() => setPopupOpen(!popupOpen)}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 12 }}>
                            {<Mobile id="mobileButtonIcon" fill="#212548" style={{ marginRight: 3 }} />} {t('mobileButton')}
                            {<Mobile id="mobileButtonIcon" fill="#212548" style={{ marginRight: 3 }} />} {t('mobileButton')}
                        </button>


                        {popupOpen && (
                            <div>
                                <div style={{ backgroundColor: 'white', padding: '10px', boxSizing: 'revert', borderRadius: '5%', width: '150px', position: 'absolute', top: document.getElementById('root').clientHeight / 3, left: -document.getElementById("root").clientWidth / 2 }}>
                                    <div style={{ display: 'block' }}>
                                        <button className={styled["back-more-info"]}
                                            onClick={() => { setPopupOpen(false) }}
                                            style={{
                                                display: 'flex', float: 'none', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center',
                                                position: 'relative', top: -35, left: 140, margin: 0, width: 40, height: 40, marginTop: 5, padding: 0
                                            }}>
                                            <Close style={{ color: '#212548', transform: 'scale(2.5)', stroke: '#212548' }} cursor="pointer" />
                                        </button>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: -35 }}>
                                        <img src={`${API_URL}${routes.qrCode.contentUrl}`} width={120} alt="Qr Code" />
                                    </div>
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black', margin: '0px 15px', fontSize: 10, lineHeight: 1.5 }}>
                                            {t('qrCodeText')}
                                            </p>
                                    </div>

                                </div>
                            </div>
                        )}

                    </div>

                )}

                {/*
                // Adaptation nécessaire avec intégration API VS

                {popupMobileDevice() && (
                    <button className={`${rightStyles["button-right-style"]} ${classRadiusButton} ${rightStyles['w-svg']}`}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.setAttribute('href', `https://tours.guidos.fun?uuid=${apiResult.id}`);
                            link.setAttribute("target", "_blank");
                            link.click();
                        }}
                        onLoad={() => {
                            document.getElementById("smallMobileIcon").setAttribute("fill", '#212548');
                        }}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        {<Mobile id="smallMobileIcon" fill="#212548" style={{ marginRight: 3 }} />} {t('mobileButton')}
                    </button>
                    )}
                */}

                {displayGeoLocationButton &&
                    <GeolocateControl
                        className={`${rightStyles["button-right-style"]} ${classRadiusButton}`}
                        style={{ position: 'relative'}}
                        positionOptions={{ enableHighAccuracy: true,  }}
                        trackUserLocation={true}
                        onGeolocate={(position) => {
                    }} />
                }

                {displayFullscreenButton &&
                    <button className={`${rightStyles["button-right-style"]} ${classRadiusButton} ${rightStyles['w-svg']}`}
                        onClick={() => {
                            const widget = document.getElementById("guidosHighlight")

                            if(iosFullScreen.current === true){
                                widget.style.height = localStorage.getItem("guidos-height");
                                widget.style.width = localStorage.getItem("guidos-width");

                                widget.style.removeProperty('position')
                                widget.style.removeProperty('z-index')
                                widget.style.removeProperty('top')
                                widget.style.removeProperty('left')


                                iosFullScreen.current = false
                                setRefreshProfile(!refreshProfile)
                            }
                            // enter
                            else{
                                // persist the current size of the widget
                                localStorage.setItem("guidos-width", widget.offsetWidth+'px');
                                localStorage.setItem("guidos-height", widget.getBoundingClientRect().height+'px')

                                widget.style.height = `${window.innerHeight}px`;
                                widget.style.width = `${window.innerWidth}px`;
                                widget.style.top = `0px`;
                                widget.style.left = `0px`;
                                widget.style.position = 'fixed';
                                widget.style.zIndex = '9999';


                                iosFullScreen.current = true
                                setRefreshProfile(!refreshProfile)
                            }

                    }}>
                        {screenfull.isFullscreen || iosFullScreen.current ? < FullscreenExit /> : <Fullscreen />}
                    </button>
                }
            </div>
        </Suspense>
    )
}

export default RightButtons;
