import React from 'react'
import ReactDOM from 'react-dom'
import './components/assets/hellix/hellixFont.css'
import './index.css'
import App from './App'
import { mapWidgetElement } from './components/utils/responsive'

function render() {
	if (mapWidgetElement() === null) {
		console.error('[Guidos]', 'No element found with id "guidosHighlight"')
		return
	}

	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById('guidosHighlight')
	)
}

function renderAtLoad() {
	// widget can be render manually by calling :
	// `window.renderGuidosMap()`
	// this is useful when using widget in a single page application (like react, vue, angular, ...)
	const widget = mapWidgetElement()
	if (widget !== null && widget.getAttribute('renderAtLoad') === 'false') {
		return
	}

	render()
}

console.log('[Guidos]', 'Loading Guidos widget')
renderAtLoad()
window.renderGuidosMap = render
