import styled from 'styled-components'


export const StyleImage = styled.div`
background-image: url(${props => props.imageUrl});
background-size: cover;
border-top-left-radius: 7px;
border-top-right-radius: 7px;
aspect-ratio: 21/9;
  width: 100%;
`

export const MoreInfoDiv = styled.div`
background-image: url(${props => props.imageUrl});
background-size: cover;
border-top-left-radius: 7px;
border-top-right-radius: 7px;
height: 175px;
min-height: 250px;
margin-bottom: 25px;
pointer-events: auto;
background-position: center;
z-index: 999;
`
