/**
 * Author: Rares Liscan
 */

import { ReactComponent as Clock } from '../assets/MoreInfo/time.svg'
import { ReactComponent as Distance } from '../assets/MoreInfo/distance.svg'
import { ReactComponent as Elevation } from '../assets/MoreInfo/elevation.svg'
import { calculateTotalAltitude, computeGeoDataArray, getTotalLength } from '../utils/calculations'

import { isMobileScreen } from '../utils/responsive'
import styles from '../Style/Tooltip.module.css'
import { useContext } from 'react'
import { RouteContext } from '../config/RouteContext'
import { updateMarkerFromMap } from '../utils/markerCoordinates'
import { apiResultAtom } from '../../state/state'
import { useAtom } from 'jotai'
let geodata = []

/**
 * @param {updateChartIndex} function updates the chart index in order to keep the dot active
 * @returns The tooltip with its current values, obtained from user mouse hover / mobile tap
 */

let lastChartIndex = -1
let alt = []

const StatsTooltip = (props) => {
	const [apiResult] = useAtom(apiResultAtom)
	const coordinates = apiResult.trail.geometry

	const { backgroundColor, buttonTextColor } = useContext(RouteContext)

	if (geodata.length === 0) {
		geodata = computeGeoDataArray(apiResult).geodataArray
	}

	const { active, chartLastIndex, chartIndex, updateChartLastIndex } = props
	if (alt.length === 0) {
		alt = calculateTotalAltitude(apiResult)
	}
	const iconStyle = {
		height: 20,
		width: 20,
		marginRight: 5,
		color: '#ffffff',
		fill: buttonTextColor ? buttonTextColor : 'white',
	}

	if (active && chartIndex < 0) {
		if (chartLastIndex > 0) {
			updateChartLastIndex(-2)
		}

		let itemIndex = -1

		if (props && props.payload && props.payload.length > 0 && props.payload[0].payload) {
			itemIndex = props.payload[0].payload.index
			const mark = [coordinates[itemIndex][0], coordinates[itemIndex][1]]
			updateMarkerFromMap(mark)
		}
		return (
			<div
				className={styles['border-tooltip']}
				style={{
					borderWidth: isMobileScreen() ? 0 : 1,
					backgroundColor: backgroundColor,
				}}
			>
				<div className={styles['btn-tooltip']}>
					<Clock fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{itemIndex > 0 ? geodata[itemIndex].time.toFixed(0) : 0} min
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Distance fill="#FFFFFF" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{itemIndex > 0 ? geodata[itemIndex].dist.toFixed(1) : 0} km
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Elevation fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{itemIndex > 0 ? alt[itemIndex].up.toFixed(0) : 0} m
					</a>
				</div>
			</div>
		)
	} else if (alt !== undefined && chartIndex > 0) {
		lastChartIndex = chartIndex
		if (chartLastIndex > 0) {
			updateChartLastIndex(-2)
		}
		return (
			<div
				className={styles['border-tooltip']}
				style={{
					borderWidth: isMobileScreen() ? 0 : 1,
					backgroundColor: backgroundColor,
				}}
			>
				<div className={styles['btn-tooltip']}>
					<Clock fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{chartIndex > 1 ? geodata[chartIndex - 1].time.toFixed(0) : 0} min
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Distance fill="#FFFFFF" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{chartIndex > 1 ? geodata[chartIndex - 1].dist.toFixed(1) : 0} km
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Elevation fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{chartIndex > 1 ? alt[chartIndex - 1].up.toFixed(0) : 0} m
					</a>
				</div>
			</div>
		)
	} else if (alt !== undefined && chartLastIndex > 0) {
		return (
			<div
				className={styles['border-tooltip']}
				style={{
					borderWidth: isMobileScreen() ? 0 : 1,
					backgroundColor: backgroundColor,
				}}
			>
				<div className={styles['btn-tooltip']}>
					<Clock fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{chartLastIndex > 1 ? geodata[chartLastIndex - 1].time.toFixed(0) : 0} min
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Distance fill="#FFFFFF" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{chartLastIndex > 1 ? geodata[chartLastIndex - 1].dist.toFixed(1) : 0} km
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Elevation fill="#FFFFFF" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{chartLastIndex > 1 ? alt[chartLastIndex - 1].up.toFixed(0) : 0} m
					</a>
				</div>
			</div>
		)
	} else if (alt !== undefined) {
		if (chartLastIndex !== lastChartIndex && !isMobileScreen()) {
			updateChartLastIndex(lastChartIndex)
		}
		return (
			<div
				className={styles['border-tooltip']}
				style={{
					borderWidth: isMobileScreen() ? 0 : 1,
					backgroundColor: backgroundColor,
				}}
			>
				<div className={styles['btn-tooltip']}>
					<Clock fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{apiResult.trail.durationMinutes} min
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Distance fill="#FFFFFF" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{getTotalLength(apiResult)} km
					</a>
				</div>

				<div className={styles['btn-tooltip']}>
					<Elevation fill="white" style={iconStyle} />
					<a href="!#" className={styles['bttn-tooltip']} style={{ color: buttonTextColor }}>
						{' '}
						{alt[alt.length - 1].up.toFixed(0)} m
					</a>
				</div>
			</div>
		)
	}
	return null
}

export default StatsTooltip
