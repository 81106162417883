import axios from "axios"
import { Entity } from "./Models"

export class Api {

    async getTrail(url: string, options?: { language?: string, key?: string }): Promise<Entity> {
        const headers: any = {
            "Accept-Language": options?.language || "fr",
        }

        try {
            const res = await axios.get(url, { headers: headers })
            return res.data as Entity
        } catch (error) {
            throw error
        }
    }
}

