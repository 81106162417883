
import { API_URL } from '../config/config'
import style from '../Style/Poi.module.css'
import { StyleImage } from './StyledComponents';
import { ReactComponent as ClosePoi } from '../assets/close-poi.svg'

const PointOfInterest = props => {

    return (
        <div className={style.container}>
            <StyleImage imageUrl={`${props.image.replace('/.jpg', '/620x260/.jpg')}`}>
                {props.close !== undefined ? (
                    <button className={style.close} onClick={props.close}>
                        <ClosePoi fill="#212547" cursor="pointer" width="8" height={8} />
                    </button>) : ''}
            </StyleImage>
            <article className={style['popup-article-style']}>
                <div className={style.element}>
                    <div className={style.heading}>
                        <h1 className={style['title-heading']}> {props.name} </h1>
                        <h2 className={style['subtitle-heading']}> {props.subname} </h2>
                    </div>
                    <div className={style.diviconpoi}>
                        <img src={`${props.icon}`} alt='point-of-interest' className={style.iconpoi} />
                    </div>
                </div>
                <p className={style['article-p-style']} > {props.description} </p>
            </article>
        </div >
    )
}

export default PointOfInterest;
