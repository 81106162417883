import { poiCategoryColor } from "./poiHelper";

export function isMobileScreen() {
    // const shadowRoot = document.querySelector("#shadowHost").shadowRoot;
    // return shadowRoot.querySelector("#root").clientWidth < 897;
    // return document.getElementById("root").shadowRoot.querySelector("#react-root").clientWidth < 897;
    return mapWidgetElement().clientWidth < 897;
}

export function popupMobileDevice() {
    return window.innerWidth < 897;
}

export function isSmallHeight() {
    // return window.innerHeight < 700;
    // const shadowRoot = document.querySelector("#shadowHost").shadowRoot;
    // return shadowRoot.querySelector("#root").clientHeight < 700;
    // return document.getElementById("root").shadowRoot.querySelector("#react-root").clientHeight < 700;
    return mapWidgetElement().clientHeight < 700;
}

export function isMobileLandscape() {
    return isMobileScreen() && isSmallHeight() && rootElement().clientWidth < 450;
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export const rootElement = () => {
    // const shadowRoot = document.querySelector("#shadowHost").shadowRoot;
    // return shadowRoot.querySelector("#root")
    return document.getElementById("root");
}

export const mapWidgetElement = () => {

    if (document.getElementById("guidosHighlight") !== null) {
        if (document.getElementById("guidosHighlight").getAttribute("fullscreen") !== null && document.getElementById("guidosHighlight").getAttribute("fullscreen") !== undefined) {
            return null;
        }
    }

    return document.getElementById("guidosHighlight");
}


export const backgroundColorStyle = (name, category) => {
    const startColor = "#00AB84";//Start/Debut
    const endColor = "#021944";//End/Fin

    if (name === "Start" || name === "Début") {
        return startColor;
    }

    if (name === "End" || name === "Fin") {
        return endColor;
    }

    return poiCategoryColor(category)
}