/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { useContext, useState, useEffect, useMemo } from 'react'


import { ReactComponent as LoaderIcon } from '../assets/loader.svg'

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceDot, Tooltip, ReferenceLine } from 'recharts';
import { RouteContext } from '../config/RouteContext';
import { calculateTotalAltitude, generateChartTicks } from '../utils/calculations';

import * as turf from '@turf/turf'
import styles from '../Style/Tooltip.module.css'
import { API_URL } from '../config/config';

import { startIconHref, endIconHref } from '../assets/svgPaths';
import CustomReferenceDot from './CustomReferenceDot';
import StatsTooltip from './Tooltip';
import { isMobileScreen, mapWidgetElement } from '../utils/responsive';
import { apiResultAtom } from '../../state/state';
import { useAtom } from 'jotai';
import { poiIconUrl } from '../utils/poiHelper';

const GraphicChart = props => {

    const [apiResult] = useAtom(apiResultAtom)

    const { setCurrentPoi, language, geodata, primaryColor, elevationProfileColor } = useContext(RouteContext);
    const { chartIndex, setChartIndex, setChartLastIndex, chartLastIndex, height } = props;
    const [poiIn, setPoiIn] = useState([]);
    const [alt, setAlt] = useState([]);
    const [maximumAltitude, setMaxAltitude] = useState(0);

    const totalDistance = geodata.geodataArray[geodata.geodataArray.length - 1].dist;

    //Near poi in geojson
    //Get all poi and build geodata array for the chart
    useEffect(poiIndex => {
        let maxAlt = 0;
        geodata.geodataArray.map(point => {
            if (point.elevation > maxAlt) {
                maxAlt = point.elevation;
            }
            return null;
        })
        setMaxAltitude(maxAlt);

        poiIndex = []

        const getCurrentPoiIndex = (line, pt, snapped) => {
            const poiData = []
            for (const poi of apiResult.trail.pois) {
                poiData.push({
                    point: [poi.base.longitude, poi.base.latitude],
                    img: poiIconUrl(poi.base.categories[0]),
                    id: poi.id,
                    name: poi.base.title,
                    description: poi.base.description,
                    category: poi.base.categories[0],
                    subname: poi.base.shortDescription,
                    iconUrl: poiIconUrl(poi.base.categories[0]),
                    tooltipImg: poiIconUrl(poi.base.categories[0]),
                })
            }

            // The X and Y points of the graph are given by the index in the line array and from the geodata latitude 
            const coordinates = apiResult.trail.geometry
            line = turf.lineString(coordinates);
            for (const points of poiData) {
                pt = turf.point([points.point[0], points.point[1]]);
                snapped = turf.nearestPointOnLine(line, pt, { units: 'kilometers' });
                const idx = snapped.properties.index;
                poiIndex.push({
                    ...points,
                    point: [snapped.properties.index, geodata.geodataArray[idx].elevation],
                    latitude: geodata.geodataArray[idx].lat,
                    longitude: geodata.geodataArray[idx].lon,
                    elevation: geodata.geodataArray[idx].elevation,
                    dist: geodata.geodataArray[idx].dist,
                })
            }

            setPoiIn(poiIndex)
        }

        getCurrentPoiIndex()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const altitudeArray = calculateTotalAltitude(apiResult);
        setAlt(altitudeArray);
    }, [alt.length, apiResult.trail.geometry])// eslint-disable-line react-hooks/exhaustive-deps

    const poiList = useMemo(() => {
        return poiIn.map(p => {
            return (
                <ReferenceDot key={p.id} x={p.dist} y={!isMobileScreen() ? p.elevation : maximumAltitude}
                    shape={
                        <CustomReferenceDot cx={p.dist} cy={!isMobileScreen() ? p.elevation : maximumAltitude} 
                            id={p.id}
                            name={p.name[language] || p.name["fr"] || ""} 
                            subname={p.subname[language] || p.name["fr"] || ""} 
                            img={p.img} 
                            category={p.category} 
                            iconUrl={p.iconUrl} 
                            setCurrentPoi={setCurrentPoi} alt={alt} />
                    }
                />
            )
        })

    }, [poiIn.length, alt.length, maximumAltitude, setCurrentPoi])


    const poiMobileReferenceLines = useMemo(() => {
        if (!isMobileScreen()) return null

        return poiIn.map(p => {
            return (
                <ReferenceLine
                    key={`ref-${p.id}`}
                    x={p.dist}
                    stroke={primaryColor}
                />
            )
        })
    }, [poiIn.length, primaryColor])


    const xAxix = useMemo(() => {
        const domain = [0, totalDistance];

        return <XAxis
            dataKey="dist"
            axisLine={true}
            interval={"preserveStartEnd"}
            tick={{ fill: '#B8B9C5' }}
            type="number"
            tickLine={false}
            domain={domain}
            ticks={generateChartTicks(totalDistance)}
            tickSize={15}
            tickFormatter={item => {
                return `${(item).toFixed(1).toString()}\u00A0km`
            }}
            style={{
                fontSize: 12
            }}
        />
    }, [totalDistance])

    const yAxix = useMemo(() => {
        return <YAxis
            type="number"
            domain={['auto', 'auto']}
            style={{
                fontSize: 12,
            }}
            dataKey={"elevation"}
            tick={{ fill: '#B8B9C5' }}
            axisLine={true}
            tickLine={false}
            tickCount={5}
            tickFormatter={number => `${number}\u00A0m`}
        />
    }, [])

    const dotFromMap = (props) => {
        const { cx, cy, index } = props;
        if (chartIndex === index || chartLastIndex === index) {
            return (
                <circle key={index} cx={cx} cy={cy} r={10} fill={elevationProfileColor} stroke="white" strokeWidth={5}></circle>
            )
        }
        return false;
    }

    const dotChart = {
        r: 10,
        stroke: "white",
        strokeWidth: 5,
    }


    const renderRefLines = () => {
        const ticks = generateChartTicks();
        const xAxisLength = ticks.length;
        let refLines = [];
        for (let i = 0; i < xAxisLength; i++) {
            if (geodata.geodataArray[ticks[i]] !== undefined) {
                refLines.push(
                    <ReferenceLine stroke={primaryColor} label=""
                        segment={[{ x: ticks[i], y: 0 }, { x: ticks[i], y: geodata.geodataArray[ticks[i]].lat }]} />
                )
            }

        }
        return refLines;
    }



    if (geodata.geodataArray.length === 0 || alt.length === 0) {
        return (
            <div className={styles["loader"]}>

                <LoaderIcon />
                <h1>Loading...</h1>
            </div>
        )
    }
    let width = mapWidgetElement().clientWidth;


    let tooltipsWidth = 100*3;
    if (window.innerWidth <= 897)
    {
        tooltipsWidth = 80*3;
    }
    if(window.innerWidth <= 360)
    {
        tooltipsWidth = 70*3;

    }


    return (
        <ResponsiveContainer width="100%" height={height} style={{display:'flex'}}>
            <AreaChart data={geodata.geodataArray}
                margin={{ top: isMobileScreen() ? 75 : 45, left: isMobileScreen() ? 0 : 20, right: isMobileScreen() ? 20 : 30, bottom: isMobileScreen() ? 0 : 20 }}
            >
                <defs>
                    {/* <linearGradient id="line" x1="0" y1="0" x2="100%" y2="0">
                        <stop offset="0%" stopColor="#00AB84" opacity={0} />
                        <stop offset={`90%`} stopColor="#00AB84" opacity={0} />
                        <stop offset={`90%`} stopColor="#D17630" opacity={0} />
                        <stop offset="100%" stopColor="#D17630" opacity={0} />
                    </linearGradient> */}

                    <linearGradient id="line" x1="0" y1="0" x2="100%" y2="0">
                        <stop offset="0%" stopColor={elevationProfileColor} opacity={0} />
                        <stop offset={`100%`} stopColor={elevationProfileColor} opacity={0} />
                    </linearGradient>

                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor={elevationProfileColor} stopOpacity={0.4} />
                        <stop offset="100%" stopColor={elevationProfileColor} stopOpacity={0} />
                    </linearGradient>

                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#129a74" stopOpacity={0} />
                        <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
                    </linearGradient>

                    <linearGradient id="colorPv" x1="0" y1="0" x2="1" y2="100%">
                        <stop offset="0%" stopColor="#82ca9d" stopOpacity={0} />
                        <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <CartesianGrid opacity={0.3} vertical={false} horizontal={true} />

                {xAxix}
                {yAxix}

                <Tooltip
                    id='chartTooltips'
                    wrapperStyle={{ visibility: 'visible' }}
                    position={{
                        y: isMobileScreen() ? 0 : -20,
                        x: isMobileScreen() ? (document.getElementById('guidosHighlight').offsetWidth - tooltipsWidth) / 2 : 80
                    }}
                    itemStyle={{ alignSelf: 'center', display:'flex', padding: '1.2% .5%' }}
                    cursor={chartIndex < 0 ? true : false}
                    children={null}
                    content={<StatsTooltip
                        updateChartLastIndex={setChartLastIndex}
                        chartLastIndex={chartLastIndex}
                        chartIndex={chartIndex}
                        setChartIndex={setChartIndex} />}
                />

                {poiList}
                {poiMobileReferenceLines}

                <ReferenceDot
                    key="startPoint"
                    height={30}
                    width={30}
                    x={0}
                    y={!isMobileScreen() ? geodata.geodataArray[0].elevation : maximumAltitude}
                    shape={<CustomReferenceDot name={language === "en" ? "Start" : "Début"}
                        img={startIconHref}
                        cx={0}
                        geodata={!isMobileScreen() ? geodata.geodataArray[0].elevation : maximumAltitude}
                        subname={language === "en" ? "Start" : "Début"} />} />


                <ReferenceDot
                    key="endPoint"
                    height={30}
                    x={totalDistance}
                    y={!isMobileScreen() ? geodata.geodataArray[geodata.geodataArray.length - 1].elevation : maximumAltitude}
                    shape={<CustomReferenceDot name={language === "en" ? "End" : "Fin"}
                        cx={totalDistance}
                        cy={!isMobileScreen() ? geodata.geodataArray[geodata.geodataArray.length - 1].elevation : maximumAltitude}
                        img={endIconHref}
                        subname={language === "en" ? "End" : "Fin"} />} />


                <Area type="monotone" dataKey="elevation" stroke="url(#line)" fillOpacity={1} strokeWidth={2} fill="url(#color)"
                    activeDot={chartIndex < 0 ? { ...dotChart } : { r: 0 }}
                    dot={dotFromMap}
                    onTouchStart={() => {
                        setChartIndex(-1);
                    }}
                    isAnimationActive={false}
                />
            </AreaChart>
        </ResponsiveContainer >
    );

}
export default GraphicChart;
