import * as turf from '@turf/turf'

let altitudeArray = []
let geodataArray = []
let totalLength = -1
let ticks = []
let minLng = 500
let minLat = 500
let maxLng = -500
let maxLat = -500
let bounds = []

let geodataLineString = null

export const resetArrays = () => {
	altitudeArray = []
	geodataArray = []
	totalLength = -1
	ticks = []
	minLng = 500
	minLat = 500
	maxLng = -500
	maxLat = -500
}

export const downloadGPX = async (name, gpxUrl) => {
	await fetch(gpxUrl)
		.then((response) => {
			return response.blob()
		})
		.then((response) => {
			const url = window.URL.createObjectURL(response)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${name}.gpx`)
			link.click()
		})
}

export const calculateTotalAltitude = (apiResult) => {
	const coordinates = apiResult.trail.geometry
	const numCoords = coordinates.length

	for (let i = 0; i < numCoords; i++) {
		const coordinate = coordinates[i]
		altitudeArray.push({
			up: coordinate[2],
			down: 0,
		})
	}

	return altitudeArray
}

export const isPointOnLine = (point) => {
	const epsilon = 0.0000001
	const turfPoint = turf.point(point)
	if (turf.booleanPointOnLine(turfPoint, geodataLineString, { epsilon: epsilon, ignoreEndVertices: true })) {
		const linePoint = turf.pointOnLine(geodataLineString, turfPoint)
		return {
			coordinates: linePoint.geometry.coordinates,
			index: linePoint.properties.index,
		}
	} else {
		return {
			coordinates: [],
			index: -1,
		}
	}
}

export function computeGeoDataArray(apiResult) {
	// fix issue with chart with 2 lines
	if (geodataArray?.length > 0)
		return {
			totalLength: totalLength,
			geodataArray: geodataArray,
		}

	const coordinates = apiResult.trail.geometry
	const duration = apiResult.trail.durationMinutes

	const totalRouteTime = duration
	const line = turf.lineString(coordinates)
	geodataLineString = line
	totalLength = turf.lineDistance(line, 'kilometers')
	const geoSlice = [coordinates[0], coordinates[1]]
	coordinates.map((data, index) => {
		if (index > 1) {
			const line = turf.lineString(geoSlice)
			geoSlice.push(coordinates[index])
			const distance = turf.lineDistance(line)
			const currentTime = (distance * totalRouteTime) / totalLength
			geodataArray.push({
				lng: data[0],
				lat: data[1],
				elevation: data[2],
				dist: distance,
				time: currentTime,
				index: index,
			})
		} else {
			geodataArray.push({
				lng: data[0],
				lat: data[1],
				elevation: data[2],
				dist: 0,
				time: 0,
				index: index,
			})
		}
		return null
	})

	return {
		totalLength: totalLength,
		geodataArray: geodataArray,
	}
}

export function getTotalLength(apiResult) {
	const coordinates = apiResult.trail.geometry

	const line = turf.lineString(coordinates)
	const km = turf.lineDistance(line, 'kilometers')

	return km.toFixed(1)
}

export const generateChartTicks = (totalDistance) => {
	const q1 = totalDistance / 4
	const mean = totalDistance / 2
	const q3 = (totalDistance / 4) * 3
	const max = totalDistance
	const min = 0

	return [min, q1, mean, q3, max]
}

export const getMapBounds = (coordinates) => {
	const numCoords = coordinates.length
	let maxLng = coordinates[0][0]
	let minLng = coordinates[0][1]
	let maxLat = coordinates[0][0]
	let minLat = coordinates[0][1]
	for (let i = 1; i < numCoords; i++) {
		const c = coordinates[i]
		if (c[0] > maxLng) {
			maxLng = c[0]
		}

		if (c[0] < minLng) {
			minLng = c[0]
		}

		if (c[1] > maxLat) {
			maxLat = c[1]
		}

		if (c[1] < minLat) {
			minLat = c[1]
		}
	}
	bounds = [
		[maxLng, minLat],
		[minLng, maxLat],
	]
	return bounds
}

export const getStartingPoints = () => {
	return {
		latitude: (minLat + maxLat) / 2,
		longitude: (minLng + maxLng) / 2,
	}
}

export function fnBrowserDetect() {
	const userAgent = navigator.userAgent.toLowerCase()
	const browsers = {
		chrome: /chrome|chromium|crios/,
		firefox: /firefox|fxios/,
		safari: /safari/,
		opera: /opr\//,
		edge: /edg/,
	}

	for (const browser in browsers) {
		if (userAgent.match(browsers[browser])) {
			return browser
		}
	}

	return 'No browser detection'
}
