import React, { useState, Suspense, useContext } from 'react'
import MoreInfo from '../Modals/MoreInfo'
import { RouteContext } from '../config/RouteContext'
import { ReactComponent as InfoBtn } from '../assets/MoreInfo/info-icon.svg'
import cardStyles from '../Style/Card.module.css';

const LeftButtons = (props) => {
    const { displayInfoButton  } = useContext(RouteContext);

    const [moreInfoModal, setMoreInfoModal] = useState(false);

    if(!displayInfoButton) return null;

    return (
        <Suspense fallback="Loading...">
            <div className={`${cardStyles['section-container']} ${cardStyles['mobile-position']}`} style={{ height: '100%', width: '100%' }}>
                <div style={{ height: '100%', width: '100%' }}>
                    {moreInfoModal && (
                        <div className='card-more-info' onMouseOver={() => props.setButtonActive(true)} onMouseOut={() => props.setButtonActive(false)}>
                            <MoreInfo setButtonActive={props.setButtonActive} close={() => {
                                setMoreInfoModal(false);
                                props.setButtonActive(false);
                            }} />
                        </div>
                    )}
                    <button onMouseOver={() => props.setButtonActive(true)} onMouseLeave={() => props.setButtonActive(false)} 
                    onClick={() => {
                        setMoreInfoModal(true);
                        props.setButtonActive(true);
                    }} className={`${cardStyles["info"]} ${cardStyles['left-btn']}`}>
                        <InfoBtn fill="#000000" style={{ width: 25 }} />
                    </button>
                </div>
            </div>
        </Suspense>
    )
}

export default LeftButtons;