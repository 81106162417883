import React, { useContext, useRef } from 'react'

import { API_URL } from '../config/config'
import { RouteContext } from '../config/RouteContext'
import { MoreInfoDiv } from './StyledComponents'
import styled from '../Style/MoreInfo.module.css'

import { ReactComponent as Gpx } from '../assets/MoreInfo/gpxicon.svg'
import { ReactComponent as Exit } from '../assets/MoreInfo/exit-more-info.svg'

import { computeGeoDataArray, downloadGPX } from '../utils/calculations'

import { ReactComponent as Clock } from '../assets/MoreInfo/time.svg'
import { ReactComponent as Distance } from '../assets/MoreInfo/distance.svg'
import { ReactComponent as Up } from '../assets/MoreInfo/updist.svg'
import { ReactComponent as Down } from '../assets/MoreInfo/downdist.svg'
import { isMobileScreen } from '../utils/responsive'
import { useTranslation } from 'react-i18next'
import { apiResultAtom } from '../../state/state'
import { useAtom } from 'jotai'

const levelBackgroundColor = (name) => {
	if (name === 'Beginner' || name === 'Débutant') {
		return '#76A2CC'
	}
	if (name === 'Novice') {
		return '#00AB84'
	}
	if (name === 'Expert') {
		return '#000000'
	}
	if (name === 'Advanced' || name === 'Avancé') {
		return '#FE0008'
	}
	return '#0097d7'
}

const MoreInfo = (props) => {
	const divRef = useRef()
	const [apiResult] = useAtom(apiResultAtom)

	const { primaryColor, backgroundColor, buttonTextColor, buttonBackgroundColor, language } = useContext(RouteContext)
	const name = apiResult.base.title[language]
	const description = apiResult.base.description[language]
	const location = apiResult.base.title[language] // TODO: faux
	const duration = apiResult.trail.durationMinutes
	const level = apiResult.base.title[language] // TODO: faux
	const image = apiResult.base.image
	const gpx = apiResult.trail.gpxUrl
	const { totalLength } = computeGeoDataArray(apiResult)
	const ascentValue = apiResult.trail.ascentMeters
	const descentValue = apiResult.trail.descentMeters

	const { t } = useTranslation()

	return (
		<div
			ref={divRef}
			className={styled['card-more-info']}
			style={{ zIndex: 920, width: isMobileScreen() ? '90%' : 'default' }}
			onWheel={(event) => {
				divRef.current.scrollTo({
					top: divRef.current.scrollTop + event.deltaY,
					behaviour: 'smooth',
				})
			}}
			onMouseOver={() => {
				props.setButtonActive(true)
			}}
			onMouseOut={() => props.setButtonActive(false)}
		>
			<div>
				<MoreInfoDiv style={{ marginBottom: isMobileScreen() ? '10px' : '25px' }} imageUrl={`${image}`}>
					<button className={styled['back-more-info']} onClick={props.close}>
						<Exit fill="#000000" cursor="pointer" />
					</button>
					<div className={styled['etq-more-info']}>
						<div className={styled['level-more-info']} style={{ backgroundColor: levelBackgroundColor(level) }}>
							{level}
						</div>
					</div>
				</MoreInfoDiv>

				<div className={styled['icon-text-more-info']}>
					<div className={`${styled['stats-more-info']} ${styled['border-more-info']} ${styled['left-more-info']}`}>
						<div className={styled['icon-more-info']}>
							<Clock height={isMobileScreen() ? 15 : 25} fill={primaryColor} style={{ color: primaryColor }} />
						</div>
						<div className={styled['type-more-info']}>{duration} min</div>
					</div>
					<div className={`${styled['stats-more-info']} ${styled['border-more-info']} ${styled['left-more-info']}`}>
						<div className={styled['icon-more-info']}>
							<Distance
								height={isMobileScreen() ? 20 : 25}
								width={30}
								style={{ marginBottom: -5, color: primaryColor }}
								fill={primaryColor}
							/>
						</div>
						<div className={styled['type-more-info']}>{totalLength.toFixed(1)} km</div>
					</div>
					<div className={`${styled['stats-more-info']} ${styled['border-more-info']} ${styled['left-more-info']}`}>
						<div className={styled['icon-more-info']}>
							<Up height={isMobileScreen() ? 15 : 25} style={{ color: primaryColor }} fill={primaryColor} />
						</div>
						<div className={styled['type-more-info']}>{ascentValue}</div>
					</div>
					<div className={styled['stats-more-info']}>
						<div className={styled['icon-more-info']}>
							<Down height={isMobileScreen() ? 15 : 25} fill={primaryColor} style={{ color: primaryColor }} />
						</div>
						<div className={styled['type-more-info']}>{descentValue}</div>
					</div>
				</div>

				<div className={`${styled['informations-more-info']} ${styled['text-more-info']}`}>
					<div className={styled['heading-more-info']}>
						<h1>{name}</h1>
						<h2>{location}</h2>
					</div>
					<p className={styled['p-more-info']}>{description}</p>
				</div>
				<div className={styled['buttons-more-info']}>
					<button
						className={styled['purple-more-info']}
						onClick={() => downloadGPX(`${name}`, `${API_URL}${gpx}`)}
						style={{ background: buttonBackgroundColor }}
					>
						<Gpx fill={buttonTextColor} style={{ color: buttonTextColor }} />
						{t('gpxMoreInfoButton')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default MoreInfo
